exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1000px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px}.hprJC{padding-left:20px;padding-right:20px}.m2ZQS{margin:0 auto;padding:0 0 40px;max-width:1000px;max-width:var(--max-content-width)}", ""]);

// exports
exports.locals = {
	"root": "hprJC",
	"container": "m2ZQS"
};